import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedService } from '../../services/shared.service';

@Component({
  standalone: true,
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule
  ]
})
export class RegisterComponent {
  user: any = {};
  passwordConfirm: string = "";
  error: string = "";

  constructor(private authService: AuthService, private router: Router, private _sharedService: SharedService) { }

  register() {
    this.error = "";
    if (this.user.password != this.passwordConfirm) {
      this.error = "Passwords don't match";
    } else {
      this.authService.register(this.user).subscribe(() => {
        this.router.navigate(['/login']);
      }, error => {
        console.error(error);
        this.error = error.error;
      });
    }
  }
  ngOnInit(): void {
    this._sharedService.setData({ type: 1, title: "Create Your Account", body: 'Already have an account? <a style="color:white;" href="/login">Sign in</a>', image: "header4.png" });
  }
}