<div class="mainContent">
  <div class="container d-flex flex-column gap-4">
    <div class="instruction-text">
      {{ pagePrefix + "instruction-text" | translate }}
    </div>

    <div class="container d-flex flex-column gap-2">
      <div (click)="handleWineAddClick()" class="container check-box">
        <div class="d-flex flex-row gap-1">
          <div class="d-flex flex-column col-2">
            <ngx-toggle
              id="wineAdded"
              name="wineAdded"
            >
            </ngx-toggle>
          </div>
          <div class="d-flex flex-column col-10 gap-2">
            <label class="form-check-label input-label">
              {{ pagePrefix + "wine-added.label" | translate }}
            </label>
            <label class="input-description">{{
              pagePrefix + "wine-added.description" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div (click)="handleWineRemoveClick()" class="container check-box">
        <div class="d-flex flex-row gap-1">
          <div class="d-flex flex-column col-2">
            <ngx-toggle
              id="wineRemoved"
              name="wineRemoved"
            >
            </ngx-toggle>
          </div>
          <div class="d-flex flex-column col-10 gap-2">
            <label class="form-check-label input-label">
              {{ pagePrefix + "wine-removed.label" | translate }}
            </label>
            <label class="input-description">{{
              pagePrefix + "wine-removed.description" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div (click)="handleStorageUnitAddClick()" class="container check-box">
        <div class="d-flex flex-row gap-1">
          <div class="d-flex flex-column col-2">
            <ngx-toggle
              id="storageAdded"
              name="storageAdded"
            >
            </ngx-toggle>
          </div>
          <div class="d-flex flex-column col-10 gap-2">
            <label class="form-check-label input-label">
              {{ pagePrefix + "storage-unit-added.label" | translate }}
            </label>
            <label class="input-description">{{
              pagePrefix + "storage-unit-added.description" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div (click)="handleStorageUnitRemoveClick()" class="container check-box">
        <div class="d-flex flex-row gap-1">
          <div class="d-flex flex-column col-2">
            <ngx-toggle
              id="storageRemoved"
              name="storageRemoved"
            >
            </ngx-toggle>
          </div>
          <div class="d-flex flex-column col-10 gap-2">
            <label class="form-check-label input-label">
              {{ pagePrefix + "storage-unit-removed.label" | translate }}
            </label>
            <label class="input-description">{{
              pagePrefix + "storage-unit-removed.description" | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
