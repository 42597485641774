import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ]
})
export class RegisterComponent {

  // form that contains all related info about the inputs
  // for this component
  form!: FormGroup;

  user: any = {};
  error: string = "";
  passwordError: string = "";
  passwordConfirmError: string = "";
  showPasswordType: boolean = false;
  showConfirmPasswordType: boolean = false

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private _sharedService: SharedService) { }

  ngOnInit(): void {
    this._sharedService.setData({ title: "Create Your Account", body: 'Already have an account? <a style="color:white;" href="/login">Sign in</a>', image: "header4.png" });
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/^.{8,}$/)]],
      passwordConfirm: ['', [Validators.required, Validators.pattern(/^.{8,}$/)]]
    });
  }

  register() {
    this.user.password = this.form.get('password')?.value;
    this.user.passwordConfirm = this.form.get('passwordConfirm')?.value;
    this.user.email = this.form.get('email')?.value;
    this.user.firstName = this.form.get('firstName')?.value;
    this.user.lastName = this.form.get('lastName')?.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }

    if (this.user.password.length < 8) {
      this.passwordError = "Password must be at least 8 characters";
    } else {
      this.passwordError = "";
    }

    if (this.user.password != this.user.passwordConfirm) {
      this.passwordConfirmError = "Passwords don't match";
    } else {
      this.passwordConfirmError = "";
    }

    if (!(this.user.password != this.user.passwordConfirm) && !(this.user.password.length < 8)
    && this.form.valid) {
      this.authService.register(this.user).subscribe(() => {
        this.router.navigate(['/login']);
      }, error => {
        console.error(error);
      });
    }
  }
  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }
}