<div class="mainContent">
  <div class="searchContainer">
    <div class="input-group mb-3 input-group-lg searchBar">
      <div class="input-group-prepend">
        <button class="btn btn-lg" type="button">
          <img src="/assets/bottle-small.svg" />
        </button>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Type the name of a wine..."
        aria-label="search"
      />
    </div>
  </div>
  <div class="mainNav">
    <div class="mainNavItems">
      <div class="mainNavItem">
        <div class="mainNavIcon">
          <img src="/assets/barcode.svg" />
        </div>
        <div class="mainNavLabel">Scan Barcode</div>
      </div>
      <div class="mainNavItem">
        <div class="mainNavIcon">
          <img src="/assets/label.svg" />
        </div>
        <div class="mainNavLabel">Search Label</div>
      </div>
      <div class="mainNavItem">
        <div class="mainNavIcon">
          <img src="/assets/browse.svg" />
        </div>
        <div class="mainNavLabel">Browse Wine</div>
      </div>
    </div>
  </div>
  <h2>Explore Your {{ winecount | number }} Wines</h2>
  <div class="row">
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/favorites.svg" />
        </div>
        <div class="subNavLabel">Favorites</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/wishlist.svg" />
        </div>
        <div class="subNavLabel">Wishlist</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/varietals.svg" />
        </div>
        <div class="subNavLabel">Varietals</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/regions.svg" />
        </div>
        <div class="subNavLabel">Regions</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/producers.svg" />
        </div>
        <div class="subNavLabel">Producers</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/vintage.svg" />
        </div>
        <div class="subNavLabel">Vintage</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/stores.svg" />
        </div>
        <div class="subNavLabel">Stores</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/places.svg" />
        </div>
        <div class="subNavLabel">Places</div>
      </div>
    </div>
  </div>
  
  <h2>Recent Activity</h2>
  <app-wine-list [wineList]="recentActivity" displaySize="small" ></app-wine-list>

  <h2 class="mb-3">Featured Wines</h2>
  <app-wine-list [wineList]="featuredWines" displaySize="large"></app-wine-list>
</div>
