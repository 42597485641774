import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-profile-options',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './profile-options.component.html',
  styleUrl: './profile-options.component.scss'
})
export class ProfileOptionsComponent {
  constructor(private route: ActivatedRoute,private _sharedService:SharedService){}

  public links : any[] = [
    {icon:"/assets/profile.svg",text:"Manage Profile Information",link:"/profile-edit"},
    {icon:"/assets/subscription.svg",text:"Manage Subscription",link:"", disabled:true},
    {icon:"/assets/manage-accounts.svg",text:"Add Users to Account",link:"/add-subuser"},
    {icon:"/assets/bell.svg",text:"Notification Preferences",link:"/notifications"}
  ];

  ngOnInit()
  {
    this._sharedService.setData({ body: "", title: "Profile", image: "", backFunction: false });
  }
}
