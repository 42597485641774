<div class="container d-flex flex-column">
    @for(wine of wineDetailService.refreshWineList; track wine.id) {
        <div *ngIf="!$first" class="dividing-row"></div>
        <div class="card-item wine-card" [class.selectedWine]="selectedWineId == wine.id" (click)="handleWineCardClick(wine)">
            <div class="wine-img-part">
                <img class="wine-image" src="{{ wine.imagePath }}" />
            </div>
            <div class="bottle-part">
                <div class="name-bottle-wrapper">
                    <div class="card-item-middle">
                        <div>
                            <div class="card-item-label wine-card-item-label">
                                {{wine.vintage}} {{wine.name}}
                            </div>
                            <div class="gray">
                                {{wine.storageName}}
                            </div>
                        </div>
                    </div>
                    <div class="card-item-in-storage" *ngIf="wineDetailService.getWineCount(wine.id) != 0">
                        <div class="notificationBottleCountContainer">
                            <div class="bottleContainer">
                                <div bottleIcon="bottleIcon">
                                    <img src="/assets/bottle-small.svg" />
                                </div>
                            </div>
                            <span class="bottleCount">{{wineDetailService.getWineCount(wine.id)}}</span>
                        </div>
                        <div class="volume">({{ wineDetailService.getVolumeString(wine.volume)}})</div>
                    </div>
                </div>
                <div>
                    @for(slotRow of wine.slots; track $index){
                        <div>
                            <p class="wineStorageLocationText">
                                Located
                                @if(slotRow.positionX != null){
                                    in Slot {{slotToLetter(slotRow.positionX, slotRow.positionY)}}
                                }
                                in the
                            </p>
                            <p class="wineStorageLocationText underline">
                                {{wine.storageName}}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</div>
