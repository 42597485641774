<div class="mainContent">
    <form>
        <div class="form-group">
            <label for="name">{{pagePrefix + 'labels.name' | translate}}</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="storageLocation.name" name="name">
        </div>
        <div class="form-group">
            <label for="description">{{pagePrefix + 'labels.description' | translate}}</label>
            <input type="text" class="form-control" id="description" [(ngModel)]="storageLocation.description" name="description">
        </div>
        <div class="form-group">
            <label for="location">{{pagePrefix + 'labels.location' | translate}}</label>
            <select id="location" name="location" [(ngModel)]="storageLocation.locationId" class="form-select">
                @for(loc of locationList; track loc.id){
                    <option [value]="loc.id">{{loc.name}}</option>
                }
            </select>
        </div>
        <div class="form-check form-switch">
            <input id="customType" type="checkbox" role="switch" class="form-check-input" [(ngModel)]="customType" name="customType">
            <label for="customType" class="form-check-label">{{pagePrefix + 'labels.custom-type' | translate}}</label>
        </div>
        <div *ngIf="!customType" class="form-group">
            <label for="vendor">{{pagePrefix + 'labels.vendor' | translate}}</label>
            <select id="vendor" class="form-select">
                <option value ="1">Vendor 1</option>
                <option value ="2">Vendor 2</option>
                <option value ="3">Vendor 3</option>
            </select>
        </div>
        <div class="form-group">
            <label for="category">{{pagePrefix + 'labels.category' | translate}}</label>
            <select id="category" class="form-select">
                <option value="1">Category 1</option>
                <option value="2">Category 2</option>
                <option value="3">Category 3</option>
            </select>
        </div>
        <div *ngIf="!customType" class="form-group">
            <label for="storageType">{{pagePrefix + 'labels.storage-type' | translate}}</label>
            <select id="storageType" class="form-select">
                <option value ="1">Wine Storage Type 1</option>
                <option value ="2">Wine Storage Type 2</option>
                <option value ="3">Wine Storage Type 3</option>
            </select>
        </div>
        <div *ngIf="customType" class="form-group">
            <label for="rackLayout">{{pagePrefix + 'labels.layout' | translate}}</label>
            <div class="radio-btn-container">
                <div class="radio-btn">
                    <button type="button" (click)="storageLocation.stacked=true" >
                        <img [alt]="pagePrefix + 'labels.stacked' | translate" src="/assets/stacked.svg" />   
                        {{pagePrefix + 'labels.stacked' | translate}}
                    </button>
                </div>
                <div class="radio-btn">
                    <button type="button" (click)="storageLocation.stacked=false;validateMin('columns');validateMin('rows');" >
                        <img [alt]="pagePrefix + 'labels.staggered' | translate" src="/assets/staggered.svg" />
                        {{pagePrefix + 'labels.staggered' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="customType" class="custom-position-layout">
            <label for="columns">{{pagePrefix + 'labels.columns' | translate}}</label>
            <label for="rows">{{pagePrefix + 'labels.rows' | translate}}</label>
            <label for="depth">{{pagePrefix + 'labels.depth' | translate}}</label>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('columns')">
                    <img [alt]="'generic-text.minus' | translate" src="/assets/minus.svg"/>
                </button>
                <input type="number" id="columns" (keyup)="validateMin('columns')" name="columns" [(ngModel)]="storageLocation.columns" />
                <button type="button" (click)="incrementNumber('columns')">
                    <img [alt]="'generic-text.plus' | translate" src="/assets/plus.svg"/>
                </button>
            </div>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('rows')">
                    <img [alt]="'generic-text.minus' | translate" src="/assets/minus.svg"/>
                </button>
                <input type="number" id="rows" name="rows" (keyup)="validateMin('rows')" [(ngModel)]="storageLocation.rows"/>
                <button type="button" (click)="incrementNumber('rows')">
                    <img [alt]="'generic-text.plus' | translate" src="/assets/plus.svg"/>
                </button>
            </div>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('depth');resetSelectedDepth();">
                    <img [alt]="'generic-text.minus' | translate" src="/assets/minus.svg"/>
                </button>
                <input type="number" id="depth" name="depth" (keyup)="validateMin('depth');resetSelectedDepth();" [(ngModel)]="storageLocation.depth" />
                <button type="button" (click)="incrementNumber('depth');resetSelectedDepth();">
                    <img [alt]="'generic-text.plus' | translate" src="/assets/plus.svg"/>
                </button>
            </div>
        </div>
        <app-wine-grid-box [storageLocation]="storageLocation" [resetSub]="resetSelectedDepthSub"/>
        <div>
            <button class="btn cc-btn save-button" (click)="saveUnit()">{{pagePrefix + 'save-button' | translate}}</button>
        </div>
    </form>
</div>
<div style="display:none" #modalToggle id="modalToggle" data-bs-toggle="modal" data-bs-target="#saveModal"></div>
<app-generic-modal 
    modalId="saveModal"
    [modalTitle]="pagePrefix + 'modal.title' | translate"
    [modalBody]="pagePrefix + 'modal.body' | translate"
    [yesButtonText]="pagePrefix + 'modal.yes' | translate"
    [noButtonText]="'generic-text.cancel' | translate"
    [displayNoButton]="true"
/>