import { Component, ElementRef, Input } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { NgxToggleModule } from "@bobbyg603/ngx-toggle";
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { WineService } from "../../services/wine.service";
import { WineDetailService } from "../../services/wine-detail.service";
import { WineDetailListComponent } from "../wine-detail-list/wine-detail-list.component";
import { UserService } from "../../services/user.service";
import { PurchaseLocationInformationComponent } from "../purchase-location-information/purchase-location-information.component";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-wine-consume",
    standalone: true,
    imports: [
        CommonModule,
        TranslatePipe,
        ReactiveFormsModule,
        NgxToggleModule,
        FormsModule,
        WineDetailListComponent,
        PurchaseLocationInformationComponent,
    ],
    templateUrl: "./wine-consume.component.html",
    styleUrl: "./wine-consume.component.scss",
})
export class WineConsumeComponent {
    constructor(
        private fb: FormBuilder,
        private _location: Location,
        private _userService: UserService,
        private _wineService: WineService,
        private _wineDetailService: WineDetailService,
        private translate: TranslateService
    ) {}

    /** List of wine to be used in detail service
     * to display in the consuem wine from
     * collection page
     */
    @Input() listOfWines: any[] = [];

    @Input("wine") wine: any;
    @Input("producerId") producerId: Number = 0;

    // ngModels of inputs that we may eventually
    // send to the backend after user presses
    // given that required inputs are entered
    public selectedWineFromCollection: any = null;

    // public wineNotesPriv: Boolean = true;
    // public tastingNotesPriv: Boolean = true;
    // public wineNotesPrivCheck: any;
    // public tasteNotesPrivCheck: any;
    public vintageDropDownOptions: string[] = [];
    public searchLocation: String = "";
    public winesInCollection: boolean = false;
    public userId: number = 0;

    /** If this boolean is true, then we will display
     * the Wine Information as shown in the
     * "Consume Wine - From Location" diagram
     * From the figma.
     */
    public purchasedAndConsumed = false;

    /** If this boolean is true, then we will display
     * the Wine Information as shown in the
     * "Consume Wine - From Collection" diagram
     * From the figma.
     */
    public fromMyCollection = false;

    // this means a user has clicked one of the two
    // consumption options
    public optionSelected = false;

    // list containing references to the star html elements
    // used for rating wine
    public stars: ElementRef[] = [];

    // the rating for a wine
    public selectedRating: number = 0;

    // form that contains all related info about the inputs
    // for this component
    public form!: FormGroup;

    // Used for translation purposes
    // check i18n folder for more info
    public pagePrefix = "wine-consume.";

    ngOnInit() {
        this.form = this.fb.group({
            amount: [1, [Validators.required]],
            wineNotes: ["", []],
            wineNotesPriv: [false, []],
            rating: [0, []],
            tastingNotes: ["", []],
            tastingNotesPriv: [false, []],
            locationType: ["", []],
            purchaseDate: ["", [Validators.required]],
            price: ["0.00", [Validators.required]],
            volume: [, [Validators.required]],
            purchaseNotes: ["", []],
            address: ["", [Validators.required]],
            vintage: [, [Validators.required]],
        });

        this._wineDetailService.wineList = this.listOfWines;
        this._wineService
            .getVintagesFromWine(this.wine.name, this.producerId)
            .subscribe((response) => {
                let vintages = response as Number[];
                for (let i = 0; i < vintages.length; i++) {
                    this.vintageDropDownOptions.push(String(vintages.at(i)));
                }
            });

        this._userService.getUser().subscribe({
            next: (response) => {
                this.userId = response.id;
            },
            error: (e) => {
                console.error(e);
            },
        });

        this._wineService
            .getSameWinNotConsumed(this.wine.id)
            .subscribe((response) => {
                let responseData: any[] = response;
                if (responseData.length != 0) {
                    this.winesInCollection = true;
                } else {
                    this.optionSelected = true;
                    this.purchasedAndConsumed = true;
                    this.fromMyCollection = false;
                    let purchaseConsumeInput = document.getElementById(
                        "purchaseConsume"
                    ) as HTMLInputElement;
                    purchaseConsumeInput.checked = true;
                }
            });
    }

    getNumberArray(numb: number) {
        return Array.from({ length: numb }, (_, i) => i + 1);
    }

    handlePurchaseClick() {
        this.purchasedAndConsumed = true;
        this.fromMyCollection = false;
        this.optionSelected = true;
    }

    handleCollectionClick() {
        this.purchasedAndConsumed = false;
        this.fromMyCollection = true;
        this.optionSelected = true;
    }

    handleStarPress(event: Event) {
        let element = event.target as HTMLInputElement;
        let value = Number(element.value);
        for (let i = 0; i < value; i++) {
            let img = this.stars[0].nativeElement
                .querySelector("label")
                .querySelector("img") as HTMLImageElement;
            img.src = "assets/star-filled.svg";
        }
    }

    decrementWine() {
        if (Number(this.form.get("amount")?.value) > 1) {
            this.form
                .get("amount")
                ?.setValue(String(Number(this.form.get("amount")?.value) - 1));
        }
    }

    incrementWine() {
        if (Number(this.form.get("amount")?.value) < 99) {
            this.form
                .get("amount")
                ?.setValue(String(Number(this.form.get("amount")?.value) + 1));
        }
    }

    onSubmit() {
        // Information taht we will send
        // to the backend
        let amount = this.form.get("amount")?.value;
        let wineNotes = this.form.get("wineNotes")?.value;
        let wineNotesPriv = this.form.get("wineNotesPriv")?.value;
        let rating = this.selectedRating;
        let tastingNotes = this.form.get("tastingNotes")?.value;
        let tastingNotesPriv = this.form.get("tastingNotesPriv")?.value;
        let locationType = this.form.get("locationType")?.value;
        let purchaseDate = this.form.get("purchaseDate")?.value;
        let price = Number(this.form.get("price")?.value);
        let volume = Number(this.form.get("volume")?.value);
        let purchaseNotes = this.form.get("purchaseNotes")?.value;
        let producerId = this.producerId;
        let address = this.form.get("address")?.value;
        let vintage = this.form.get("vintage")?.value;
        let wineName = this.wine.name;
        let wineFromCollection = this.selectedWineFromCollection;

        let wineConsumptionInfo: any = {
            amount,
            wineNotes,
            wineNotesPriv,
            rating,
            tastingNotes,
            tastingNotesPriv,
            locationType,
            purchaseDate,
            price,
            volume,
            purchaseNotes,
            producerId,
            address,
            vintage,
            wineName,
            wineFromCollection,
        };

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        } else {
            this._wineService
                .postWineConsumption(wineConsumptionInfo)
                .subscribe((response) => {
                    // NO OP
                });
        }
    }

    recieveWineInfo($event: any) {
        this.selectedWineFromCollection = $event;
    }

    goBack() {
        this._location.back();
    }
}
