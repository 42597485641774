import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wine-list',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './wine-list.component.html',
  styleUrl: './wine-list.component.scss'
})
export class WineListComponent {
  @Input() wineList: any = '';
  @Input() displaySize: any = 'small';

}
