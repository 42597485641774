import { Component } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { NgxToggleModule } from "@bobbyg603/ngx-toggle";
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { WineService } from "../../services/wine.service";
import { PurchaseLocationInformationComponent } from "../purchase-location-information/purchase-location-information.component";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { WineGridBoxComponent } from "../wine-grid-box/wine-grid-box.component";
import { BehaviorSubject, Subject } from "rxjs";
import { SharedService } from "../../services/shared.service";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-add-to-collection",
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxToggleModule,
        FormsModule,
        PurchaseLocationInformationComponent,
        RouterModule,
        WineGridBoxComponent,
        TranslatePipe,
    ],
    templateUrl: "./add-to-collection.component.html",
    styleUrl: "./add-to-collection.component.scss",
})
export class AddToCollectionComponent {
    // Used for translation purposes
    // check i18n folder for more info
    public pagePrefix = "add-to-collection.";

    // The wine object representation of the wine
    // the user selected in the previous page
    public wine: any = {};

    // The wineId that is obtain via the snapshot
    // route in order to query the backend
    // for the wine object
    public wineId: number = 0;

    // List of vintages avaliable
    // based on the wine the user
    // selected on the previous page
    public vintages: String[] = [];

    // The avaliable storage locations
    // the user has avaliable
    public locations: any[] = [];

    // A list of slots based on thier string
    // representation. For example
    public selectedSlotArray: String[] = [];

    // form that contains all related info about the inputs
    // for this component
    public form!: FormGroup;

    // Radio button HTML Input Elements for their respective
    // wine status in order to manipulate
    public collectionRadio = document.getElementById(
        "collection"
    ) as HTMLInputElement;
    public boxRadio = document.getElementById("box") as HTMLInputElement;
    public transitRadio = document.getElementById(
        "transit"
    ) as HTMLInputElement;

    // Used for wine grid so error of undefined
    // input does not display
    public resetSub: Subject<void> = new Subject<void>();

    // The wine status. Changes based on which wine status
    // radio button the user clicks on. Default is empty
    // string.
    public wineStatus = "";

    // The Storage location the user selects in the location
    // drop down menu
    public storageLocation: any;

    // The selected vintage the user clicks on
    // in the vintage list at the top of the page.
    // Default is an empty string
    public selectedVintage: String = "";

    // A Behavioral Subject to monitor changes to the
    // amount input in order for wine grid component
    // to delete selected wine slots that go over
    // the amount the user specified, and to prevent
    // the user from clicking on more wine slots
    // than the amount the user specified
    public amountToSelect = new BehaviorSubject<number>(1);

    // Whether ot not to display an error message
    // on an invalid form submission
    public displayErrorMessage: boolean = false;

    constructor(
        private _sharedService: SharedService,
        private _storageService: StorageService,
        private _route: ActivatedRoute,
        private fb: FormBuilder,
        private _location: Location,
        private _wineService: WineService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this._sharedService.setData({
            title: "Add Bottles",
            image: "",
            backFunction: true,
        });

        this.translate.get(this.pagePrefix + "page-header").subscribe((title) =>
            this._sharedService.setData({
                body: "",
                title,
                image: "",
                backFunction: true,
            })
        );

        this.collectionRadio = document.getElementById(
            "collection"
        ) as HTMLInputElement;
        this.boxRadio = document.getElementById("box") as HTMLInputElement;
        this.transitRadio = document.getElementById(
            "transit"
        ) as HTMLInputElement;

        this.form = this.fb.group({
            amount: ["1", [Validators.required]],
            wineNotes: ["", []],
            wineNotesPriv: [false, []],
            purchaseDate: ["", [Validators.required]],
            price: ["0.00", [Validators.required]],
            volume: [, [Validators.required]],
            purchaseNotes: ["", []],
            address: ["", [Validators.required]],
            location: ["", [Validators.required]],
        });

        this._storageService.getBrowseList().subscribe((response) => {
            for (let location of response) {
                console.log(location.location);
                this.locations.push(location.location);
            }
        });

        this.wineId = Number(this._route.snapshot.paramMap.get("id") ?? "");
        this._wineService
            .getWineDetails(this.wineId)
            .subscribe((wineResponse) => {
                this.wine = wineResponse;
                this._wineService
                    .getVintagesFromWine(this.wine.name, this.wine.producer.id)
                    .subscribe((response) => {
                        let vintages = response as Number[];
                        for (let i = 0; i < vintages.length; i++) {
                            this.vintages.push(String(vintages.at(i)));
                        }
                    });
            });
    }

    decrementWine() {
        if (Number(this.form.get("amount")?.value) > 1) {
            this.form
                .get("amount")
                ?.setValue(String(Number(this.form.get("amount")?.value) - 1));
            this.amountToSelect.next(this.amountToSelect.getValue() - 1);
        }
    }

    incrementWine() {
        if (Number(this.form.get("amount")?.value) < 99) {
            this.form
                .get("amount")
                ?.setValue(String(Number(this.form.get("amount")?.value) + 1));
            this.amountToSelect.next(this.amountToSelect.getValue() + 1);
        }
    }

    vintageClick(year: any) {
        let vintageRadio = document.getElementById(
            "vintage " + year
        ) as HTMLInputElement;
        vintageRadio.checked = !vintageRadio.checked;
        this.selectedVintage = year;
    }

    handleCollectionClick() {
        this.collectionRadio.checked = true;
        this.wineStatus = "collection";
        this.transitRadio.checked = false;
        this.boxRadio.checked = false;
    }

    handleBoxClick() {
        this.collectionRadio.checked = false;
        this.transitRadio.checked = false;
        this.boxRadio.checked = true;
        this.wineStatus = "box";
    }

    handleTransitClick() {
        this.collectionRadio.checked = false;
        this.transitRadio.checked = true;
        this.wineStatus = "transit";
        this.boxRadio.checked = false;
    }

    changeLocation(location: any) {
        console.log(location);
        this.storageLocation = location;
    }

    onSelectedSlotEvent(selectedSlotArray: any) {
        this.selectedSlotArray = selectedSlotArray;
    }

    onSubmit() {
        if (
            this.form.invalid &&
            (this.wineStatus == "" || this.selectedVintage != "")
        ) {
            this.form.markAllAsTouched;
            this.displayErrorMessage = true;
        } else {
            let vintage = Number(this.selectedVintage);
            let amount = Number(this.form.get("amount")?.value);
            let volume = Number(this.form.get("volume")?.value);
            let storageLocation = this.storageLocation;
            let price = Number(this.form.get("price")?.value);
            let address = this.form.get("address")?.value;
            let purchaseNotes = this.form.get("purchase")?.value;
            let purchaseDate = this.form.get("purchaseDate")?.value;
            let wineNotes = this.form.get("wineNotes")?.value;
            let wineNotesPriv = this.form.get("wineNotesPriv")?.value;
            let wineStatus = this.wineStatus;
            let wine = this.wine;
            let selectedSlotArray = this.selectedSlotArray;

            let requestBody = {
                wine,
                amount,
                volume,
                vintage,
                storageLocation,
                price,
                address,
                purchaseNotes,
                purchaseDate,
                wineNotes,
                wineNotesPriv,
                selectedSlotArray,
                wineStatus,
            };

            console.log(requestBody);

            this._wineService
                .postAddToCollection(requestBody)
                .subscribe((response) => {
                    // NO OP
                });

            this._location.back();
        }
    }
}
