<div class="mainContent">
    <div class="card-item search-container">
        <img src="/assets/search.svg" class="search-svg" />
        <input type="text" placeholder="Search Collection" class="form-control" id="filter" (keyup)="filterService.filterWine();" [(ngModel)]="filterService.filterText">
    </div>
    <div class="wine-count-wrap">
        <app-wine-count-filter></app-wine-count-filter>
    </div>
    <div class="row">
        <div class="col-md-3 col-6 gy-2 gx-2" routerLink="/browse-wine-list/favorite/Favorites/false">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/favorites.svg" />
                </div>
                <div class="subNavLabel">Favorites</div>
            </div>
        </div>
        <div class="col-md-3 col-6 gy-2 gx-2" routerLink="/browse-wine-list/consumed">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/wishlist.svg" />
                </div>
                <div class="subNavLabel">Wishlist</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-6 gy-2 gx-2" routerLink="/storage-list">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/varietals.svg" />
                </div>
                <div class="subNavLabel">Wine Storage</div>
            </div>
        </div>
        <div class="col-md-3 col-6 gy-2 gx-2" routerLink="/activity">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/regions.svg" />
                </div>
                <div class="subNavLabel">Recent Activity</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-6 gy-2 gx-2" routerLink="/browse-wine-list/consumed">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/producers.svg" />
                </div>
                <div class="subNavLabel">Consumed</div>
            </div>
        </div>
        <div class="col-md-3 col-6 gy-2 gx-2 disabled">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/vintage.svg" />
                </div>
                <div class="subNavLabel">Ready to Drink</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-6 gy-2 gx-2 disabled">
            <div class="subNavItem">
                <div class="subNavIcon">
                    <img src="/assets/wine-book.svg" />
                </div>
                <div class="subNavLabel">Wine List</div>
            </div>
        </div>
    </div>
    <app-sorted-wine-list></app-sorted-wine-list>
</div>