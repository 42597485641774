import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private sharedData$ = new BehaviorSubject<any>({title: '', hero: ''});
  sharedData = this.sharedData$.asObservable();

  constructor() { }

  setData(data: any): void {
    this.sharedData$.next(data);
  }
}
