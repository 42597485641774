import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule,
    FormsModule,
    RouterModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  credentials: any = {};
  error: string = "";
  success: string = "";
  constructor(private authService: AuthService, private router: Router, private _sharedService: SharedService) { }

  forgotPassword() {
    this.error = "";
    this.success = "";
    this.authService.forgotPassword(this.credentials).subscribe({
      next: (response) => {
        if(response){
          this.success = "Successfully sent the reset email.";
        }
        else{
          this.error = "Failed to send reset email. Please check your entered email address and try again.";
        }
      }, 
      error: () => {
        this.error = "Failed to send reset email";
      }
    });

  }
  ngOnInit(): void {
    this._sharedService.setData({ title: "Forgot Password?", body: 'Enter the email address you used when you joined and we\'ll send you instructions to reset your password.<br/>Remember your password? <a style="color:white;" href="/login">Sign in</a>', image: "header2.png"});
  }
}
