import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'bootstrap/dist/js/bootstrap.bundle';
import { Subject, Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { WineGridBoxComponent } from "../wine-grid-box/wine-grid-box.component";
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-storage-detail',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    WineGridBoxComponent,
    GenericModalComponent,
    TranslatePipe
],
  templateUrl: './storage-detail.component.html',
  styleUrl: './storage-detail.component.scss'
})
export class StorageDetailComponent {
  pagePrefix = "storage-detail.";
  resetSelectedDepthSub: Subject<void> = new Subject();
  constructor(private translate: TranslateService,private modalService:ModalService, private router: Router,private route: ActivatedRoute,private fb: FormBuilder,private _sharedService: SharedService,private _storageService:StorageService){}
  form!: FormGroup;
  storageLocation:any = {};
  originalLocation:any = null;
  storageLocationId:number = 0;
  customType:boolean = true;
  selectedDepth: number =1;
  locationList: any[] = [];
  private yesSub!: Subscription;
  @ViewChild('modalToggle') confirmModalBtn!:ElementRef;

  ngOnInit(): void {
    this.yesSub = this.modalService.yesClick.subscribe(_result=> this.updateUnit());
    this.locationList.push({id:0,name:""});
    this._storageService.getLocationList().subscribe({
      next: (res)=>{
        res.forEach((location:any)=>this.locationList.push({id:location.id,name:location.name}));
      },
      error: (e)=>{
        console.log(e);
      }
    });
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
    let unitId = this.route.snapshot.paramMap.get("id") ?? "";
    if(unitId == ""){
      this.storageLocation.id = 0;
      this.storageLocation.rows = 3;
      this.storageLocation.columns = 5;
      this.storageLocation.depth = 1;
      this.storageLocation.stacked = true;
    }
    else{
      this._storageService.getStorageLocation(unitId).subscribe((location)=>{
        this.storageLocation = location;
        this.originalLocation = structuredClone(location);
      });
    }
    this.translate.get(this.pagePrefix + 'page-header').subscribe((title)=> 
      this._sharedService.setData({ body: "", title, image: "", backFunction: true })
    );
  }
  ngOnDestroy(){
    this.yesSub.unsubscribe();
  }

    decrimentNumber(key:string){
      this.storageLocation[key]--;
      this.validateMin(key);
    }

    incrementNumber(key:string){
      this.storageLocation[key]++;
      this.validateMin(key);
    }

    validateMin(key:string){
      let min = ((key == 'columns' || key == 'rows') && !this.storageLocation.stacked) ? 2 : 1;
      if(this.storageLocation[key] < min){
        this.storageLocation[key] = min;
      }
    }
    resetSelectedDepth(){
      this.resetSelectedDepthSub.next();
    }

    updateUnit(){
      this._storageService.updateStorageLocation(this.storageLocation).subscribe({
        next:(_res)=>this.router.navigate(['/storage-list']),
        error:(error)=>{
          console.log(error);
        }
      });
    }
    
    saveUnit(){
      if(this.storageLocation.id == 0){
        this._storageService.postNewUnit(this.storageLocation).subscribe((res)=>{
          this.storageLocation.id = res;
          this.router.navigate(['/storage-device-config/' + res]);
        });
      }
      else{
        //check to see if some rows would be lost, display a warning that inventory might get unlinked
        if(
          this.originalLocation.depth > this.storageLocation.depth
          || this.originalLocation.columns > this.storageLocation.columns
          || this.originalLocation.rows > this.storageLocation.rows
          || (this.originalLocation.stacked && !this.storageLocation.stacked)
        ){
          //show the confirm warning
          this.confirmModalBtn.nativeElement.click(); 
        }
        else{
          this.updateUnit();
        }
      }
    }
}
