<div class="custom-box-wrapper">
    <div class="custom-wine-box">
        @for(row of getNumberArray(storageLocation.rows); track $index; let rowIdx = $index; let rowOdd = $odd;let rowEven = $even){
            <div class="honeycomb-wrapper">
                @for(column of getNumberArray(storageLocation.columns); track $index; let colIdx = $index){
                    @if(storageLocation.stacked || !$last || ($last && rowOdd)){
                        <div class="circle-wrapper">
                            @if(storageLocation.depth > 1 && ($first && !storageLocation.stacked && rowEven)){
                                <div class="circle-shadow stagger-first"></div>
                            }
                            <div (click)="selectSlot(column, row)" [ngClass]="{'selected': containsSlotKey(column, row, selectedDepth) && addToCollection, 'occupied': this.isOccupiedSlot(column, row, selectedDepth) && addToCollection  }" class="slot-circle">{{getColumnNumber(column)}}{{row}}</div>
                            @if(storageLocation.depth > 1 && !$last){
                                <div class="circle-shadow"></div>
                            }
                        </div>
                    }
                }
            </div>
        }
    </div>
</div>
@if(storageLocation.depth > 1){
    <div class="depth-nav-wrapper">
        <div class="depth-nav">
            <button class="arrow-btn" (click)="decreaseDepth()">
                <img src="/assets/left-arrow.svg" />
            </button>
            @if(storageLocation.depth > 2){
                @for(depthSpot of getNumberArray(storageLocation.depth); track $index; let colIdx = $index){
                    <button type="button" (click)="selectedDepth=depthSpot" [class]="'depth-btn' + (selectedDepth == depthSpot ? ' selected' : '')">{{depthSpot}}</button>
                }
            }
            @else {
                <button type="button" (click)="selectedDepth=1" [class]="'depth-btn' + (selectedDepth == 1 ? ' selected' : '')">Front</button>
                <button type="button" (click)="selectedDepth=2" [class]="'depth-btn' + (selectedDepth == 2 ? ' selected' : '')">Back</button>
            }
            <button class="arrow-btn" (click)="increaseDepth()">
                <img src="/assets/right-arrow.svg" />
            </button>
        </div>
    </div>
}