<div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <form (ngSubmit)="forgotPassword()">
              <span class="error text-danger">{{error}}</span>
              <div class="form-group">
                <label for="email">Email Address</label>
                <input type="text" placeholder="example@example.com" class="form-control" id="email" [(ngModel)]="credentials.email" name="email">
              </div>
              <button type="submit" class="btn cc-btn col-12 mt-3">Send Reset Instructions</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>