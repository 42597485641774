import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule } from '@angular/forms';
import { numberToLetter } from '../../utils';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-wine-grid-box',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule],
  templateUrl: './wine-grid-box.component.html',
  styleUrl: './wine-grid-box.component.scss'
})
export class WineGridBoxComponent {

  constructor(private _storageService: StorageService, private route: ActivatedRoute,private fb: FormBuilder,private _sharedService: SharedService){}
  @Input() storageLocation: any = {};

  // Boolean to add slot selection functionality
  // depending if this component is
  // being used for the add to collection
  // component
  @Input() addToCollection: boolean = false;

  @Input() amountToSelect = new BehaviorSubject<number>(1);
  @Input() resetSub!: Subject<void>;

  // An event emitter to inform the add to collection
  // component a list of slots based on thier string
  // representation that the user has selected
  @Output() selectedSlot: EventEmitter<any> = new EventEmitter<any>();

  selectedDepth:number = 1;

  // The amount of slots the user has
  // selected in the add to collection
  // component
  public amountSelected: number = 0;

  // The max amount of slots that the user
  // can select
  public amountLimit: number = 0;

  // Array of slots based on their string
  // representation of 'x,y,z'
  public slotsArray: String[] = [];

  // Hashmap of slot string representation to
  // their location in slotsArray
  public slotHashMap: Map<String, Number> = new Map();

  // Set that contrains string representations of
  // slots that are already occupied by a wine
  // bottle
  public occupiedSlotsSet: Set<String> = new Set();

  ngOnInit(){
    this.resetSub.subscribe(()=>{
      this.selectedDepth = 1;
    });

    this.amountToSelect.subscribe((response) => {
      this.amountLimit = response;
      while (this.amountSelected>this.amountLimit) {
        let extraSlot: String = String(this.slotsArray.at(this.slotsArray.length-1));
        this.slotsArray.splice(this.slotsArray.length-1, 1);
        this.slotHashMap.delete(extraSlot);
        this.amountSelected--;
      }
    })


    this._storageService.getOccupiedSlots(this.storageLocation.id).subscribe((response) => {
      let storageLocationSlots: any[] = response;
      console.log(response);
      for (let i=0; i<storageLocationSlots.length; i++) {
        let slot = storageLocationSlots[i];
        let slotStringRepresentation = String(slot.positionX) + "," + String(slot.positionY) + "," + String(slot.positionZ);
        this.occupiedSlotsSet.add(slotStringRepresentation);
      }
    })


  }

  getColumnNumber(column:number){
    return numberToLetter(column);
  }

  getNumberArray(numb:number){
    return Array.from({ length: numb }, (_, i) => i + 1)
  }

  increaseDepth(){
    if(this.selectedDepth < this.storageLocation.depth){
      this.selectedDepth++;
    }
  }

  decreaseDepth(){
    if(this.selectedDepth > 1){
      this.selectedDepth--;
    }
  }

  selectSlot(col: Number, row: Number) {
    console.log(this.slotsArray);
    if (this.addToCollection) {
      let depth = this.selectedDepth;
      if (this.containsSlotKey(col, row, depth)) {
        this.deleteSlotKey(col, row, depth);
        this.amountSelected--;
      } else if (this.amountSelected<this.amountLimit && !this.isOccupiedSlot(col, row, depth)) {
        this.amountSelected++;
        this.createSlotKey(col, row, depth);
        this.selectedSlot.emit(this.slotsArray);
      }
    }
  }

  createSlotKey(col: Number, row: Number, depth: Number) {
    this.slotHashMap.set(`${col},${row},${depth}`,this.slotsArray.length);
    this.slotsArray.push(`${col},${row},${depth}`);
  }

  containsSlotKey(col: Number, row: Number, depth: Number): boolean {
    return this.slotHashMap.has(`${col},${row},${depth}`)
  }

  deleteSlotKey(col: Number, row: Number, depth: Number) {
    this.slotsArray.splice(Number(this.slotHashMap.get(`${col},${row},${depth}`)),1);
    this.slotHashMap.delete(`${col},${row},${depth}`);
  }

  isOccupiedSlot(col: Number, row: Number, depth: Number): boolean {
    return this.occupiedSlotsSet.has(`${col},${row},${depth}`);
  }

}
