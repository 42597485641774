@if (displaySize == "large"){ 
    <div class="row">
        @for(wine of wineList; track $index) {
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="card mb-3">
                    <div class="card-body">
                        <div class="wineCardContainer">
                            <img style="width: 100px; height: 240px" src="{{ wine.image }}" />
                            <div class="wineCardDetails">
                                <div class="colorContainer">
                                    <div [style.background]="wine.color" class="colorIcon"></div>
                                    <span class="colorLabel">{{ wine.type }}</span>
                                </div>
                                <div class="wineCardMain">
                                    <span class="wineCardVineyard">{{ wine.vineyard }}</span>
                                    <span class="wineCardName">{{ wine.name }}</span>
                                </div>
                                <div class="wineCardLocation">{{ wine.location }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
} @else {
    @for(wine of wineList; track $index) {
        <div class="wineList" [ngClass]="{'border-top': $index != 0 }">
            <img class="wineImage" [src]="wine.image" />
            <div class="mainSection">
                <div class="mainHeader">
                    <span class="name">{{ wine.name }}</span>
                    <span class="location">{{ wine.location }}</span>
                </div>
                <div class="colorContainer">
                    <div [style.background]="wine.color" class="colorIcon"></div>
                    <span class="colorLabel">{{ wine.type }}</span>
                </div>
            </div>
            <div class="notificationSection">
                <div class="notificationBottleCountContainer">
                    <div class="bottleContainer">
                        <div bottleIcon="bottleIcon">
                            <img src="/assets/bottle-small.svg" />
                        </div>
                    </div>
                    <span class="bottleCount">{{ wine.bottleCount }}</span>
                </div>
                <div class="volume">{{ wine.volume }}</div>
            </div>
        </div>
    }
}
