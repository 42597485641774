import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, RouterOutlet,Router, NavigationEnd, NavigationStart, ChildrenOutletContexts } from '@angular/router';
import { HeaderComponent, HeaderOptions } from './components/header/header.component';
import { routeTransition } from './route-transition';
import { FooterNavComponent } from "./components/footer-nav/footer-nav.component";
import { ScrollService } from './services/scroll.service';
import { SharedService } from './services/shared.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterNavComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    routeTransition
  ]
})
export class AppComponent {
  title = 'CellarConnect';
  screenWidth!: number;
  //default it to something, itll get set on first navigation
  leavingHeader: HeaderOptions = HeaderOptions.loggedOutWithImage;
  comingHeader: HeaderOptions = HeaderOptions.loggedOutWithImage;
  constructor(public translate: TranslateService,protected route: ActivatedRoute,private router: Router, protected scrollService:ScrollService,private sharedService: SharedService,private contexts: ChildrenOutletContexts) {
    this.onResize();
    this.translate.addLangs(['en-US']);
    this.translate.setDefaultLang('en-US');
    this.translate.use('en-US');
  }
  onScroll(){
    this.scrollService.handleScroll();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      //Sets the headers for the slide transition because they have different heights
      if (event instanceof NavigationStart) {
        this.leavingHeader = this.contexts.getContext('primary')?.route?.snapshot?.data?.['header'];
      }
      if (event instanceof NavigationEnd) {
        this.comingHeader = this.contexts.getContext('primary')?.route?.snapshot?.data?.['header'];
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  //If the header sizes are updated, this also needs to be updated
  getSizeFromEnum(header:HeaderOptions){
    switch(header) {
      case HeaderOptions.noImageSmallBack:
      case HeaderOptions.noImageSmallWineIcon:
        return 100;
      case HeaderOptions.loggedOutWithImage:
      case HeaderOptions.loggedInWithImage:
      case HeaderOptions.wineDetailWithImage:
      default:
        return 240;
    }
  }

  getNextRouteHeaderSize() {
    return this.getSizeFromEnum(this.contexts.getContext('primary')?.route?.snapshot?.data?.['header']);
  }

  //Different speeds for different window sizes
  getAnimationSpeedValue(): string {
    return this.screenWidth <= 800 ? '300ms' : '600ms';
  }
}
