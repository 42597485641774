<div class="container mt-5" [hidden]="!loaded">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form (ngSubmit)="login()">
            <span class="error text-danger">{{error}}</span>
            <div class="form-group">
              <label for="email">Email Address</label>
              <input type="text" class="form-control" id="email" [(ngModel)]="credentials.email" name="email">
            </div>
            <div class="form-group password-row">
              <div class="password-group">
                <label for="password">Password</label>
                <a class="forgot-password" routerLink="/forgot-password">Forgot?</a>
              </div>
              <input type="password" class="form-control" id="password" [(ngModel)]="credentials.password" name="password">
            </div>
            <button type="submit" class="btn cc-btn col-12 mt-3">Sign in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>