<ng-container *ngIf="data.type == 1">
  <div
    class="headerHeroImage"
    [style.background-image]="
      'linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),url(/assets/' +
      data.image +
      ')'
    "
  ></div>
  <div class="headerContent">
    <img src="/assets/logo.png" />
    <div *ifChanges="data.title" [@myTrigger]>
      <h1 class="headerTitle">{{ data.title }}</h1>
      <div class="headerText" [innerHTML]="data.body"></div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="data.type == 2">
  <div
    class="headerHeroImageSmall"
    [style.background-image]="
      'linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),url(/assets/' +
      data.image +
      ')'
    "
  ></div>
  <div class="headerContentSmall">
    <img src="/assets/logo_small.svg" class="left" />
    <div class="headerTextSmall" [innerHTML]="data.body"></div>
    <div class="dropdown">
      <a
        href="#"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src="/assets/account.svg" class="right" />
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button class="dropdown-item" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
  <div *ifChanges="data.title" [@myTrigger]>
    <h1 class="headerTitle">{{ data.title }}</h1>
  </div>
</ng-container>
