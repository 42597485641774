import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { WineFilterService } from '../../services/wine-filter.service';
import { WineService } from '../../services/wine.service';
import { SortedWineListComponent } from "../sorted-wine-list/sorted-wine-list.component";
import { WineCountFilterComponent } from '../wine-count-filter/wine-count-filter.component';


@Component({
  selector: 'app-collection',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, SortedWineListComponent,WineCountFilterComponent],
  templateUrl: './collection.component.html',
  styleUrl: './collection.component.scss'
})
export class CollectionComponent {
  constructor(private _sharedService: SharedService,private _wineService: WineService,private _wineFilterService: WineFilterService) { }

  ngOnInit(): void {
    this.filterService.resetData();
    //wait for both requests to finish
    //list of wines
    //map of wine Id to how many bottles exist in the inventory
    const browseRes = this._wineService.getCollection();
    const countsRes = this._wineService.getWineCounts();
    forkJoin([browseRes,countsRes]).subscribe({
      next:([res1,res2]) =>{
      this._wineFilterService.wineList = res1;
      this._wineFilterService.wineCounts = res2;
      this.filterService.filterWine();
    },
    error:(error)=>{
      console.log(error);
    }});

    this._sharedService.setData({body: "", title: "Your Collection", image: "", backFunction: true });
  }

  get filterService(){
    return this._wineFilterService;
  }

}
