import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'app-location-detail-list',
  standalone: true,
  imports: [GenericModalComponent,CommonModule,RouterModule],
  templateUrl: './location-detail-list.component.html',
  styleUrl: './location-detail-list.component.scss'
})
export class LocationDetailListComponent {

  nonStorageUnitList: any = [];
  constructor(private modalService:ModalService, private sharedService:SharedService,private storageService:StorageService){}
  selectedUnit:number = -1;
  alertMessage:string = '';
  alertError:boolean = false;
  ngOnInit()
  {
    this.sharedService.setData({ body: "", title: "Storage Locations" });
    this.storageService.getBrowseNonStorageUnitList().subscribe((results)=>{
      this.nonStorageUnitList = results;
    });
  }

  getNumber(numb:any){
    if(numb == null){
      return '--';
    }
    return numb.toFixed(0);
  }

  removeUnit(){
    this.alertMessage = '';
    this.storageService.removeUnit(this.selectedUnit).subscribe({next: (result)=>{
      this.alertMessage = 'Successfully removed the storage location';
      this.alertError = false;
      this.storageService.getBrowseNonStorageUnitList().subscribe((results)=>{
        this.nonStorageUnitList = results;
      });
    },error: (error)=>{
      console.error(error);
      this.alertMessage = 'Error encountered removing the storage location';
      this.alertError = true;
    }});
  }
}
