<form [formGroup]="form">
  <div class="d-flex flex-column gap-3">
    <div class="section-header">{{ header }}</div>
    <div *ngIf="!isPurchaseForm" class="d-flex flex-column" style="gap: 6px">
      <label class="input-label" for="location">{{
        pagePrefix + "location-type.label" | translate
      }}</label>
      <select
        class="form-control"
        name="location"
        formControlName="locationType"
      >
        <option value="" disabled selected>
          {{ pagePrefix + "location-type.label" | translate }}
        </option>
        <option value="Restaurant">
          {{ pagePrefix + "location-type.types.restaurant" | translate }}
        </option>
        <option value="Bar">
          {{ pagePrefix + "location-type.types.bar" | translate }}
        </option>
      </select>
    </div>
    <div class="d-flex flex-column" style="gap: 8px">
      <label class="input-label" for="searchLocation">{{
        pagePrefix + "search.label" | translate
      }}</label>
      <div class="d-flex align-items-start gap-2">
        <img
          class="icon"
          src="assets/search2.svg"
          style="align-self: center"
          [alt]="pagePrefix + 'search-icon' | translate"
        />
        <div class="text-input-container">
          <input
            name="date"
            class="form-control"
            type="text"
            [placeholder]="pagePrefix + 'search.placeholder' | translate"
            formControlName="address"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-column" style="gap: 6px">
      <label class="input-label" for="date">{{
        pagePrefix + "date.label" | translate
      }}</label>
      <div class="row">
        <div class="col-4">
          <div class="d-flex align-items-start gap-2" onfocus="">
            <img
              class="icon"
              src="assets/date.svg"
              style="align-self: center"
              [alt]="pagePrefix + 'date-icon' | translate"
            />
            <input
              name="date"
              class="date-input"
              type="text"
              onfocus="this.type='date'"
              onblur="this.type='text'"
              [placeholder]="pagePrefix + 'date.placeholder' | translate"
              formControlName="purchaseDate"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column gap-2">
      <label class="input-label" for="price">{{
        pagePrefix + "price" | translate
      }}</label>
      <div class="d-flex align-items-start">
        <img
          class="icon"
          src="assets/money.svg"
          style="align-self: center"
          [alt]="pagePrefix + 'money-icon' | translate"
        />
        <input
          type="text"
          class="form-control"
          name="price"
          id="price"
          aria-label="Amount (to the nearest dollar)"
          (change)="onPriceChange()"
          formControlName="price"
        />
      </div>
    </div>
    <div>
      <div class="d-flex flex-column" style="gap: 8px">
        <label class="input-label" for="purchaseNotes">{{
          pagePrefix + "purchase-notes.label" | translate
        }}</label>
        <div class="text-input-container">
          <textarea
            class="form-control"
            id="purchaseNotes"
            name="purchaseNotes"
            [placeholder]="
              pagePrefix + 'purchase-notes.placeholder' | translate
            "
            formControlName="purchaseNotes"
          ></textarea>
        </div>
      </div>
      <label class="tasting-notes-sublabel mt-1">
        {{ pagePrefix + "purchase-notes.description" | translate }}
      </label>
    </div>
  </div>
</form>
