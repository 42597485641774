<div class="container mt-5" [hidden]="!loaded">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form (ngSubmit)="login()" [formGroup]="form" #formDirective="ngForm">
            <div *ngIf="alertMessage != ''">
              <div class="alert alert-success alert-bar" role="alert">
                <div>
                  {{alertMessage}}
                </div>
                <span class="close-alert" (click)="alertMessage = ''">X</span>
              </div>
            </div>
            <span class="error text-danger">{{error}}</span>
            <div class="form-group">
              <label for="email">Email Address</label>
              <input type="text" class="form-control" id="email" formControlName="email" name="email">
            </div>
            <div class="form-group password-row">
              <div class="password-group">
                <label for="password">Password</label>
                <a class="forgot-password" routerLink="/forgot-password">Forgot?</a>
              </div>
              <input [type]="(showPasswordType ? 'text':'password')" class="form-control" id="password" formControlName="password" name="password">
              <img [src]="'/assets/' + (showPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showPasswordType")' class="eye-svg" alt="eye icon"/>
            </div>
            <button type="submit" class="btn cc-btn col-12 mt-3">Sign in</button>
          </form>
          <div class="container pt-4 pb-3">
            <div class="d-flex justify-content-center align-items- gap-1">
              <div class="social-login-line"></div>
              <div class="social-login-divider-text">Or login with</div>
              <div class="social-login-line"></div>
            </div>
            <div class="d-flex align-items-start row pt-2">
              <div class="col-6">
                 <div googleSigninButton class="d-flex justify-content-center align-items-start google pt-2 pb-2 ps-3 pe-3">
                  <asl-google-signin-button type='standard' size='medium'></asl-google-signin-button>
                </div>
              </div>
              <div class="col-6">
                <div (click)="signInWithFB()" class="d-flex justify-content-center align-items-start facebook pt-2 ps-3 pe-3 pb-2" style="cursor: pointer">
                  <img class="social-login-icon" src="assets/facebook_icon.svg" alt="facebook icon" />
                  <label class="social-login-text">Facebook</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>