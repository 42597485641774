import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScrollService } from '../../services/scroll.service';
import { WineFilterService } from '../../services/wine-filter.service';
import { WineDetailService } from '../../services/wine-detail.service';
import { Subscription } from 'rxjs';
import { CommonModule, Location } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { WineService } from '../../services/wine.service';
import { getSlotText } from '../../utils';

@Component({
  selector: 'app-wine-detail-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './wine-detail-list.component.html',
  styleUrl: './wine-detail-list.component.scss'
})
export class WineDetailListComponent {

  constructor(private _scrollService: ScrollService,private _wineDetailService: WineDetailService, private _wineService: WineService) { }
  private detailSub!: Subscription;
  @Input() wineConsumptionPage: boolean = false;
  @Output() selectedWineEmitter: EventEmitter<any> = new EventEmitter<any>();
  public wineSelected = false;
  public selectedWineId = -1;

  ngOnInit(): void {
    this._scrollService.resetMax();

    this.detailSub = this._scrollService.refilter$.subscribe({
      next:()=>{
        this._wineDetailService.refreshWineList();
      }
    });
  }

  ngOnDestroy(){
    this._scrollService.resetMax();
    this.detailSub.unsubscribe();
  }

  get wineDetailService(){
    return this._wineDetailService;
  }

  get wineService(){
    return this._wineService;
  }

  slotToLetter(x : Number, y : Number) {
    return getSlotText({positionX : x, positionY : y});
  }

  handleWineCardClick(wine: any) {
    if (this.wineConsumptionPage) {
      this.selectedWineEmitter.emit(wine);
      this.selectedWineId = wine.id;
    }
  }


}
