import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScrollService } from '../../services/scroll.service';
import { SharedService } from '../../services/shared.service';
import { WineService } from '../../services/wine.service';

@Component({
  selector: 'app-browse-wine',
  standalone: true,
  imports: [CommonModule,RouterModule, ReactiveFormsModule, FormsModule ],
  templateUrl: './browse-wine.component.html',
  styleUrl: './browse-wine.component.scss'
})
export class BrowseWineComponent {

  public category: string = "";
  public selectionText: string = "";
  public value: string = "";
  public searchResults: any = {};
  public categoryKey: string = "";
  public filterText: string = "";
  private filterSub!: Subscription;
  //the map for current->next key for each category
  public keyMap: any = {
    "varietals": {"":"wine-varietal","wine-varietal":"final-step"},
    "regions": {"":"sub-region","sub-region":"final-step"},
    "producers": {"":"final-step"},
    "vintage": {"":"final-step"},
    "purchased-locations": {"":"final-step"},
    "consumed-locations": {"":"final-step"}
  };

  public categories: any[] = [
    {icon:"/assets/varietals.svg", text:"Varietals",disabled:false,link:"/varietals"},
    {icon:"/assets/regions.svg", text:"Regions",disabled:false,link:"/regions"},
    {icon:"/assets/producers.svg", text:"Producers",disabled:false,link:"/producers"},
    {icon:"/assets/vintage.svg", text:"Vintage",disabled:false,link:"/vintage"},
    {icon:"/assets/stores.svg", text:"Purchased Locations",disabled:false,link:"/purchased-locations"},
    {icon:"/assets/places.svg", text:"Consumed Locations",disabled:false,link:"/consumed-locations"},
    {icon:"/assets/friends.svg", text:"Friends",disabled:true,link:""},
  ]

  constructor(private route: ActivatedRoute, private _sharedService: SharedService,private location: Location,private _wineService: WineService,private _scrollService: ScrollService) { }

  ngOnInit(): void {
    this._scrollService.resetMax();
    this.category = this.route.snapshot.paramMap.get("category") ?? "";
    this.categoryKey = this.route.snapshot.paramMap.get("categoryKey") ?? "";
    this.selectionText = this.route.snapshot.paramMap.get("selectionText") ?? "";
    this.value = this.route.snapshot.paramMap.get("value") ?? "";
    if(this.category != ""){
      this._wineService.getBrowseList(this.category,this.categoryKey,this.value).subscribe({
        next: (response) => {
          this.searchResults = response;
          this._scrollService.setMax(Object.keys(this.searchResults).length);
        }, 
        error: (e) => {
          console.error(e);
        }
      });
    }
    this._sharedService.setData({ body: "", title: "Browse Wine", image: "", backFunction: false });
  }

  getSearchKeys(){
    let keys = Object.keys(this.searchResults);
    let results: string[] = keys.slice();
    const filterVal = this.filterText.toLowerCase();
    return results.filter(o => this.searchResults[o].toLowerCase().includes(filterVal)).slice(0,this._scrollService.maxDisplayVal);
  }

  getNextKey(){
    return this.keyMap[this.category][this.categoryKey];
  }

  getSubCatLink(value: string,key: string){
    let nextKey = this.getNextKey();
    if(nextKey == 'final-step'){
      //need the region and sub region for the results
      if(this.category == "regions"){
        return `/browse-wine-list/${this.category}/${key}/${value}/${this.value}`;
      }
      else{
        return `/browse-wine-list/${this.category}/${key}/${value}`;
      }
    }
    else{
      return `/browse-wine/${this.category}/${nextKey}/${key}/${value}`;
    }
    
  }

  getHeaderText(){
    let output = "";
    if(this.selectionText != ""){
      output = this.selectionText + 's';
    }
    else{
      output = this.category;
    }
    output = output.replace("-", " ");
    return output;
  }

  goBack():void{
    this.location.back();
  }
}


