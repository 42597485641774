import { CommonModule, Location } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';
import { SearchService } from '../../services/search.service';
import { SharedService } from '../../services/shared.service';
import { WineFilterService } from '../../services/wine-filter.service';
import { WineService } from '../../services/wine.service';
import { SortedWineListComponent } from "../sorted-wine-list/sorted-wine-list.component";

@Component({
  selector: 'app-search-results',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, SortedWineListComponent],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss'
})


export class SearchResultsComponent {

public advancedQueryInProgress: boolean = false;
private subject: Subject<string> = new Subject();
public displayProducerResults = false;
public producerId: string = "";

  constructor(private elRef: ElementRef, private _wineFilterService: WineFilterService, private router: Router, private _searchService: SearchService, private _wineService: WineService, private route: ActivatedRoute,private _sharedService: SharedService,private fb: FormBuilder,private location: Location){}

  ngOnInit(): void {

    this._wineFilterService.resetData();
    this.elRef.nativeElement.querySelector('#input').focus();
    this.producerId = this.route.snapshot.paramMap.get("id") ?? "";

    this.subject.pipe(debounceTime(1000))
      .subscribe(
        value => {
          if (this.advancedQueryInProgress == false) {
            this.updateQuerySimple();
          }
        }
      )

    this._sharedService.setData({ body: "", title: "Search", image: "", backFunction: true });
  }

  // this is called each time a user
  // updates their query without pressing
  // backspace
  updateQuerySimple() {
    this.searchService.resetData();
    this.wineFilterService.resetData();
    this.displayProducerResults = false;
    let filteredQuery = this.searchService.query.replace(this.searchService.filterExtraSpacesPattern, " ").trim();
    this.wineService.getSearchResults(filteredQuery).subscribe({
      next: (response) => {
        this.searchService.presortedProducerList = response['producerList'];
        this.searchService.presortedWineList = response['wineList'];
        this.wineFilterService.wineList = response['wineList'];
        this.searchService.sortResultList();
        this.wineFilterService.filterWine();
      },
      error: (e) => {
        console.log("Error", e);
        this.searchService.resetData();
        this.wineFilterService.resetData();
      }
    });
    this.searchService.previousQuery=this.searchService.query;
  }

  // this is called when the user presses enter
  // that displays advanced details of all
  // wines and producers form our search results
  updateQueryAdvanced() {
    if (this.displayProducerResults==false) {
      this.displayProducerResults=true;
    } else {
      this.displayProducerResults=false;
    }
  }

  onKeyDown(event: KeyboardEvent) {

    if (event.key == 'Enter') {
      this.updateQueryAdvanced();
      this.advancedQueryInProgress = true;
    }
    if (this.searchService.query.trim()=="") {
        this.searchService.resetData();
        this.wineFilterService.resetData();
    }
    else if (this.searchService.isCurrentQueryDifferent() && this.searchService.query.trim()!="") {
      this.subject.next(this.searchService.query);
    }
      this.advancedQueryInProgress = false;

  }

  goBack():void{
    this.searchService.resetData();
    this.location.back();
  }

  get searchService(): SearchService {
    return this._searchService;
  }

  get wineService(): WineService {
    return this._wineService;
  }

  get wineFilterService(): WineFilterService {
    return this._wineFilterService;
  }

  get searchResults(): any {
    return this.searchService.searchResults;
  }

}
