import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-wine-image',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './wine-image.component.html',
  styleUrl: './wine-image.component.scss'
})
export class WineImageComponent {

  imageSource: string = "";

  constructor(private _route: ActivatedRoute, private _sharedService: SharedService) { }

  ngOnInit() {

    this._sharedService.setData({ title: "Wine Detail", image:"", backFunction: true });
    this.imageSource = decodeURIComponent(this._route.snapshot.paramMap.get("img") ?? "");

  }

}
