import { Component } from "@angular/core";
import { NgxToggleModule } from "@bobbyg603/ngx-toggle";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { SharedService } from "../../services/shared.service";

@Component({
    selector: "app-manage-notifications",
    standalone: true,
    imports: [NgxToggleModule, TranslatePipe],
    templateUrl: "./manage-notifications.component.html",
    styleUrl: "./manage-notifications.component.scss",
})
export class ManageNotificationsComponent {
    // Used for translation purposes
    // check i18n folder for more info
    public pagePrefix: String = "manage-notifications.";

    public wineAddedNotifications: Boolean = false;
    public wineRemovedNotifications: Boolean = false;
    public storageUnitAddedNotifications: Boolean = false;
    public storageUnitRemovedNotifications: Boolean = false;

    constructor(
        private _sharedService: SharedService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.get(this.pagePrefix + "page-header").subscribe((title) =>
            this._sharedService.setData({
                body: "",
                title,
                image: "",
                backFunction: true,
            })
        );
    }

    handleWineAddClick() {
        let wineAddedRadio = document.getElementById(
            "wineAdded"
        ) as HTMLInputElement;
        wineAddedRadio.checked = !wineAddedRadio.checked;
        this.wineAddedNotifications = !this.wineAddedNotifications;
    }

    handleStorageUnitRemoveClick() {
        let wineRemovedRadio = document.getElementById(
            "wineRemoved"
        ) as HTMLInputElement;
        wineRemovedRadio.checked = !wineRemovedRadio.checked;
        this.wineRemovedNotifications = !this.wineRemovedNotifications;
    }

    handleStorageUnitAddClick() {
        let storageAddRadio = document.getElementById(
            "storageAdded"
        ) as HTMLInputElement;
        storageAddRadio.checked = !storageAddRadio.checked;
        this.storageUnitAddedNotifications =
            !this.storageUnitAddedNotifications;
    }

    handleWineRemoveClick() {
        let storageRemoveRadio = document.getElementById(
            "storageAdded"
        ) as HTMLInputElement;
        storageRemoveRadio.checked = !storageRemoveRadio.checked;
        this.storageUnitRemovedNotifications =
            !this.storageUnitRemovedNotifications;
    }
}
