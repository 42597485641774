import { Component } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-storage-device-config',
  standalone: true,
  imports: [CommonModule,FormsModule,TranslatePipe],
  templateUrl: './storage-device-config.component.html',
  styleUrl: './storage-device-config.component.scss'
})
export class StorageDeviceConfigComponent {
  pagePrefix:string = "storage-device-config.";
  device: any= {};
  locationId!:number; 

  //Key matches the property of the flag on the BudDevice
  budOptions: any[] = [
    {key:"standardBud",value:false},
    {key:"bottleBud",value:false},
    {key:"motionSensorBud",value:false},
    {key:"bulkBud",value:false},
    {key:"chillerControlBud",value:false}
  ];

  constructor(private translate: TranslateService,private route: ActivatedRoute,private _sharedService: SharedService,private _storageService:StorageService,private router: Router){}
  
  ngOnInit(){
    this.locationId = Number(this.route.snapshot.paramMap.get("id") ?? "");
    this.translate.get(this.pagePrefix + 'page-header').subscribe((title)=> 
      this._sharedService.setData({ body: "", title, image: "", backFunction: true })
    );
    this._storageService.getLocationDevice(this.locationId).subscribe((device)=>{
      this.device = device;
      this.budOptions.forEach((row)=>{
        row.value = this.device[row.key];
      });
    });
  }

  saveUnit()
  {
    this.budOptions.forEach((row)=>{
      this.device[row.key] = row.value;
    });
    this._storageService.postDevice(this.device,this.locationId).subscribe({
      next: (_res)=>{
        this.router.navigate(['/storage-list']);
      },
      error: (error)=>{
        console.log(error);
      }
    });
  }
}
