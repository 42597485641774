import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { DecimalPipe } from '@angular/common';
import { WineListComponent } from '../wine-list/wine-list.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [DecimalPipe, WineListComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  public winecount: number = 0;
  public featuredWines: any = [];
  public recentActivity: any = [];

  constructor(private authService: AuthService, private router: Router, private _sharedService: SharedService, private _userService: UserService) { }

  ngOnInit(): void {
    this._sharedService.setData({ type: 2, body: "Good Evening!", title: "Search for Wine", image: "header4.png" });
    this._userService.getUser().subscribe({
      next: (response) => {
        console.info(response);
      }, 
      error: (e) => {
        console.error(e);
      }
    });
    //TODO: Replace with api call to get user data for wine count
    this.winecount = 1234;
    this.recentActivity = [
      { name: "Test Wine 1", image: "https://via.placeholder.com/56x76", vineyard: "Chateau Lynch Bages", location: "Texas Hill Country", type: "Red Blend", color: "#8B0000", bottleCount: 5, volume: "750ml" },
      { name: "Test Wine 2", image: "https://via.placeholder.com/56x76", vineyard: "RAEN", location: "Texas Hill Country", type: "Red Blend", color: "#ABCDEF", bottleCount: 3, volume: "750ml" },
      { name: "Test Wine 3", image: "https://via.placeholder.com/56x76", vineyard: "Occidental", location: "Texas Hill Country", type: "Champaigne", color: "#8BFFFF", bottleCount: 2, volume: "750ml" }
    ];
    this.featuredWines = [
      { name: "Paullac 2020", image: "https://via.placeholder.com/100x240", vineyard: "Chateau Lynch Bages", location: "Bordeaux, France", type: "Red Blend", color: "#8B0000", bottleCount: 5, volume: "750ml" },
      { name: "Pinot Noir Sonoma Coast Roval St. Robert Cuvee 2021", image: "https://via.placeholder.com/100x240", vineyard: "RAEN", location: "Sonoma Coast, California", type: "Wine", color: "#ABCDEF", bottleCount: 3, volume: "750ml" },
      { name: "Pinot Noir West Sonoma Coast Freestone-Occidental 2021", image: "https://via.placeholder.com/100x240", vineyard: "Occidental", location: "West Sonoma Coast, California", type: "Champaigne", color: "#8BFFFF", bottleCount: 2, volume: "750ml" },
      { name: "Brunello di Montalcino 2018", image: "https://via.placeholder.com/100x240", vineyard: "Argiano", location: "Tuscany, Italy", type: "Dark Blue", color: "#123456", bottleCount: 1, volume: "750ml" }
    ];
  }
}
