<div class="mainContent">
    <div class="header-text">{{'storage-device-config.sub-header' | translate}}</div>
    @for(checkboxGroup of budOptions; track $index){
        <div class="checkbox-group">
            <div class="check-wrapper">
                <label [for]="checkboxGroup.key" [class.checkedBox]="checkboxGroup.value" (click)="checkboxGroup.value = !checkboxGroup.value" class="custom-box">
                    <img alt="check" *ngIf="checkboxGroup.value" src="/assets/check.svg" />
                </label>
                <input [id]="checkboxGroup.key" type="checkbox" style="display: none;"/>
            </div>
            <div>
                <div class="sub-header-text">
                    {{(pagePrefix + checkboxGroup.key + ".header") | translate}}
                </div>
                <div class="detail-text">
                    {{(pagePrefix + checkboxGroup.key + ".description") | translate}}
                </div>
            </div>
        </div>
    }
    <div>
        <button class="btn cc-btn save-button" style="width:100%" (click)="saveUnit()">{{'storage-device-config.save-unit' | translate}}</button>
    </div>
</div>