import { HttpInterceptorFn } from '@angular/common/http';
import { request } from 'http';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  let token = localStorage.getItem('access_token');
  let expiration = new Date(Number(localStorage.getItem('access_token_expiration')));
  if (token && expiration && expiration > new Date()) {
    req = req.clone({
      setHeaders: {
        Authorization: "Bearer " + token
      }
    });
  } else {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
  }
  return next(req);
};
