import { CommonModule, Location } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { ScrollService } from '../../services/scroll.service';
import { SharedService } from "../../services/shared.service";
import { UserService } from "../../services/user.service";
import { WineDetailService } from "../../services/wine-detail.service";
import { WineService } from "../../services/wine.service";
import { WineConsumeComponent } from "../wine-consume/wine-consume.component";
import { WineDetailListComponent } from "../wine-detail-list/wine-detail-list.component";


@Component({
  selector: 'app-wine',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule, WineDetailListComponent, WineConsumeComponent],
  templateUrl: './wine.component.html',
  styleUrl: './wine.component.scss'
})
export class WineComponent {



  constructor(private _scrollService: ScrollService, private authService: AuthService, private location: Location, private router: Router, private _sharedService: SharedService, private _userService: UserService, private route: ActivatedRoute, private _wineService: WineService, private _wineDetailService : WineDetailService) { }

  public user: any = {};
  public wineID: string = "";
  public wine: any = {producer:{}};
  public userId: number = 0;
  public isWishlisted: boolean = false;
  public isFavorited: boolean = false;
  public zeroBottlesInCollection: boolean = true;
  public consume = false;
  public fromMyCollection: boolean = false;
  public listOfWine: any[] = [];
  public wineImageCode: string = "";


  ngOnInit() : void {
    this._sharedService.setData({ body: "Wine Detail", image:"", backFunction: true });
    this.wineID = this.route.snapshot.paramMap.get("id") ?? "";
    this.detailService.resetData();

    this._userService.getUser().subscribe({
      next: (response) => {
        this.userId = response.id;
        this.user = response;
      },
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getWineDetails((Number(this.wineID))).subscribe({
      next: (response) => {
        this.wine = response;
        this.wineImageCode = encodeURIComponent(this.wine.imagePath);
        console.log(this.wineImageCode);
        this._sharedService.setData({ body: "Wine Detail", image: this.wine.imagePath, backFunction: true });
      },
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getSameWinNotConsumed(Number(this.wineID)).subscribe({
      next:(res1) =>{
      // We loop through every wine we have that is simialr to the one
      // on this page in order to set storage/slot properties
      // to display on the front end
      let preRes = res1;
      for (let i=0; i< preRes.length; i++) {
        preRes[i].slots = [];
        this._wineService.getSlotAndStorageForWine(preRes[i].id).subscribe({next: (storageRes)=>{
            storageRes.forEach((slotRow:any)=>{
              let row = {positionX:null,positionY:null,storageName:""};
              if(slotRow.slot != null){
                row = {positionX:slotRow.slot.positionX,positionY:slotRow.slot.positionY,storageName: slotRow.storageLocation.name};
              }
              else{
                row = {positionX:null,positionY:null,storageName: slotRow.storageLocation.name};
              }
              preRes[i].storageName = row.storageName;
              preRes[i].slots.push(row);
            });
         }});
      }
      this._wineDetailService.wineList = preRes;
      this.listOfWine = preRes;
      this.detailService.refreshList();
    },
    error:(error)=>{
      console.log(error);
    }});

    this._wineService.getWineCounts().subscribe({
      next: (response) => {
        this._wineDetailService.wineCounts = response;
      },
      error: (e) => {
        console.log(e);
      }
    });

     this._wineService.getWineWishListStatus(Number(this.wineID)).subscribe({
      next: (response) => {
        this.isWishlisted = response;
      },
      error: (e) => {
        this.isWishlisted = false;
      }
    });

    this._wineService.getFavoriteStatus(Number(this.wineID)).subscribe({
      next: (response) => {
        console.log(response);
        this.isFavorited = response;
      },
      error: (e) => {
        this.isFavorited = false;
      }
    });

    if (this._wineDetailService.wineCounts<=0) {
      // This being set to false will
      // repleace the "# bottles in colletion"
      // label with a wishlist button
      this.zeroBottlesInCollection=false;
    }
  }

  get detailService(){
    return this._wineDetailService;
  }

  get wineService() {
    return this._wineService;
  }


  handleConsumeClick() {
    this.consume = !this.consume;
  }

  removeFromFavorites(wineID: number) : void {
    this.wineService.postFavoriteRemoval(wineID).subscribe(response => {
      this.isWishlisted = false;
      this.isFavorited = false;
    });
  }

  addToFavorites(wineID: number) : void {
    this.wineService.postNewFavorite(wineID).subscribe(response => {
      this.isFavorited = true;
    });
  }

  updateWishList(wineID: number, add: boolean) : void {
    this.wineService.postWishListUpdate(wineID, add).subscribe(response => {
      this.isWishlisted = add;
      this.isFavorited = add;
    });
  }


}
