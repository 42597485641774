import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule,ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { SocialAuthService, GoogleSigninButtonModule, SocialUser, SocialLoginModule, FacebookLoginProvider } from "@abacritt/angularx-social-login";

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CommonModule,
    SocialLoginModule,
    GoogleSigninButtonModule
  ]
})

export class LoginComponent {

  // form that contains all related info about the inputs
  // for this component
  form!: FormGroup;

  credentials: any = {};
  error: string = "";
  loaded: boolean = false;
  showPasswordType: boolean = false;
  alertMessage: string = "";
  accessToken = '';
  user: SocialUser | undefined;
  loggedIn: boolean = false;

  constructor(private _socialAuthService: SocialAuthService, private fb: FormBuilder, private authService: AuthService,private route: ActivatedRoute, private router: Router, private _sharedService: SharedService) { }

  ngOnInit(): void {

    this._socialAuthService.authState.subscribe((response) => {
        console.log(response);
        this.credentials.firstName=response.firstName;
        this.credentials.lastName=response.lastName;
        this.credentials.email=response.email;
        if (response.provider == SocialProvider.FACEBOOK) {
          this.credentials.socialAuthToken = response.authToken;
          this.credentials.socialProvider= SocialProvider.FACEBOOK;
        } else {
          this.credentials.socialAuthToken = response.idToken;
          this.credentials.socialProvider = SocialProvider.GOOGLE;
        }
        this.login();
    });

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.loaded = true;
    this.route.queryParams.pipe().subscribe({
      next: (param) =>{
        if(param["msg"] != null){
          this.alertMessage = param["msg"];
        }
      }
    });
    this._sharedService.setData({ title: "Welcome Back", body: 'Don\'t have an account? <a style="color:white;" href="/register">Sign up</a>', image: "header1.png"});
  }

  login() {

    if (this.form.invalid && (this.credentials.socialProvider==null || this.credentials.socialProvider=="")) {
      this.form.markAllAsTouched();
    }

    if (this.credentials.socialProvider==null || this.credentials.socialProvider=="") {
      this.credentials.password = this.form.get('password')?.value;
      this.credentials.email = this.form.get('email')?.value;
      this.credentials.socialProvider="";
    }

    this.error = "";

    this.authService.login(this.credentials).subscribe({
      next: (response) => {
        console.log(response);
        var expires = new Date();
        expires.setHours(expires.getHours() + 1);
        localStorage.setItem('access_token', response.token);
        localStorage.setItem('access_token_expiration', expires.getTime().toString());
        localStorage.setItem('incomplete',response.incomplete);
        if(response.incomplete){
          this.router.navigate(['/complete-profile']);
        }
        else{
          this.router.navigate(['/home']);
        }
      },
      error: () => {
        this.error = "Invalid Email/Password";
      }
    });

  }

  signInWithFB(): void {
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this._socialAuthService.signOut();
  }

  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }

}

export enum SocialProvider {
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK"
};