import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedService } from '../../services/shared.service';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ]
})
export class LoginComponent {
  credentials: any = {};
  error: string = "";
  loaded: boolean = false;
  constructor(private authService: AuthService, private router: Router, private _sharedService: SharedService) { }

  login() {
    this.error = "";
    this.authService.login(this.credentials).subscribe({
      next: (response) => {
        var expires = new Date();
        expires.setHours(expires.getHours() + 1);
        localStorage.setItem('access_token', response.token);
        localStorage.setItem('access_token_expiration', expires.getTime().toString());
        this.router.navigate(['/home']);
      }, 
      error: () => {
        this.error = "Invalid Email/Password";
      }
    });

  }
  ngOnInit(): void {
    this.loaded = true;
    this._sharedService.setData({ type: 1, title: "Welcome Back", body: 'Don\'t have an account? <a style="color:white;" href="/register">Sign up</a>', image: "header1.png"});
  }
}