<div class="mainContent">
  <div class="container">
  <div class="container card-item">
    <div class="row wine-row mt-3">
      <div class="col-6">
        <p class="heading-text">
          {{ wine.name }}
        </p>
        <p class="subheading-text">
          {{ wine.region }}
        </p>
        <div>
          <div class="wine-color">
            <div [style.background]="wine.color" class="color-icon"></div>
            <span class="color-label">{{ wine.varietal }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="wine-image-container">
          <img class="wine-image" src="{{ wine.imagePath }}" />
          <div
            class="wine-zoom-container"
            [routerLink]="'/wine/' + wineID + '/' + wineImageCode"
          >
            <img class="wine-zoom-icon" src="assets/search.svg" />
          </div>
        </div>
      </div>
      <div class="container favorite-buttons mt-4">
        <div
          *ngIf="!isFavorited"
          (click)="addToFavorites(wine.id)"
          class="wineDetailCardButton"
        >
          <img class="card-item-icon small-icon" src="assets/heart.svg" /> Add
          to Favorites
        </div>
        <div
          *ngIf="isFavorited"
          (click)="removeFromFavorites(wine.id)"
          class="wineDetailCardButton"
        >
          <img class="card-item-icon small-icon" src="assets/heart.svg" />
          Remove from Favorites
        </div>
        <div
          *ngIf="detailService.getInStorageCount() != 0"
          class="wineDetailCardButton"
        >
          <img
            class="card-item-icon small-icon"
            src="assets/bottle-small.svg"
          />
          <span>
            {{ detailService.getInStorageCount() }} Bottles in Collection
          </span>
        </div>
        <div
          *ngIf="detailService.getInStorageCount() == 0 && !isWishlisted"
          (click)="updateWishList(wine.id, true)"
          class="wineDetailCardButton"
        >
          <img class="card-item-icon small-icon" src="assets/favorites.svg" />
          Add to Wishlist
        </div>
        <div
          *ngIf="detailService.getInStorageCount() == 0 && isWishlisted"
          (click)="updateWishList(wine.id, false)"
          class="wineDetailCardButton"
        >
          <img class="card-item-icon small-icon" src="assets/favorites.svg" />
          Remove from Wishlist
        </div>
      </div>
    </div>
  </div>
  <div class="row button-row mt-3 mb-4" *ngIf="!consume">
    <div class="col-6">
      <button
        type="button"
        (click)="handleConsumeClick()"
        class="btn cc-btn"
        style="width: 100%"
      >
        Consume
      </button>
    </div>
    <div class="col-6">
      <button
        type="button"
        class="btn adddToCollectionButton"
        style="width: 100%"
        [routerLink]="'add'"
      >
        Add to Collection
      </button>
    </div>
  </div>
  <div *ngIf="!consume">
    <div class="wineListContainer">
      <div class="wineListTextContainer">
        <div class="wineListText">
          {{ detailService.getInStorageCount() }} Bottles in Your Collection
        </div>
      </div>
      <app-wine-detail-list> </app-wine-detail-list>
      <div class="dividing-row mb-4"></div>
    </div>
    <div class="detailsBoxContainer">
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Type</div>
        <div class="detailText">
          {{ wine.type }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Producer</div>
        <div class="detailText">
          {{ wine.producer.name }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Variety</div>
        <div class="detailText">
          {{ wine.varietal }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Designation</div>
        <div class="detailText">
          {{ wine.designation }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Vineyard</div>
        <div class="detailText">
          {{ wine.vinyard }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Country</div>
        <div class="detailText">
          {{ wine.country }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Region</div>
        <div class="detailText">
          {{ wine.region }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Sub Region</div>
        <div class="detailText">
          {{ wine.subregion }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Area</div>
        <div class="detailText">
          {{ wine.area }}
        </div>
      </div>
      <div class="dividing-row"></div>
    </div>
  </div>
</div>
<div *ngIf="consume" class="mt-4">
  <app-wine-consume
    [listOfWines]="listOfWine"
    [wine]="wine"
    [producerId]="wine.producer.id"
  ></app-wine-consume>
</div>
</div>
