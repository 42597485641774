import * as i0 from '@angular/core';
import { EventEmitter, forwardRef, Component, Input, Output, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
class NgxToggleComponent {
  constructor() {
    this.id = 'ngx-toggle';
    this.checked = false;
    this.disabled = false;
    this.checkedChange = new EventEmitter();
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  onClick(event) {
    const checked = event.target.checked;
    this.onChange(checked);
    this.onTouched();
    this.checkedChange.emit(checked);
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  writeValue(checked) {
    this.checked = checked;
    this.checkedChange.emit(checked);
  }
}
NgxToggleComponent.ɵfac = function NgxToggleComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxToggleComponent)();
};
NgxToggleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxToggleComponent,
  selectors: [["ngx-toggle"]],
  inputs: {
    id: "id",
    checked: "checked",
    disabled: "disabled"
  },
  outputs: {
    checkedChange: "checkedChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NgxToggleComponent),
    multi: true
  }])],
  decls: 3,
  vars: 5,
  consts: [[1, "form-switch"], ["type", "checkbox", 3, "click", "id", "checked", "disabled"]],
  template: function NgxToggleComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "label", 0)(1, "input", 1);
      i0.ɵɵlistener("click", function NgxToggleComponent_Template_input_click_1_listener($event) {
        return ctx.onClick($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelement(2, "i");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵclassProp("disabled", ctx.disabled);
      i0.ɵɵproperty("id", ctx.id)("checked", ctx.checked)("disabled", ctx.disabled);
    }
  },
  styles: [".form-switch[_ngcontent-%COMP%]{display:inline-block;cursor:pointer;padding:0;-webkit-tap-highlight-color:transparent}.form-switch[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{position:relative;display:inline-block;width:46px;height:26px;background-color:#d3d3d3;border-radius:23px;vertical-align:text-bottom;transition:all .3s linear}.form-switch[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]:before{content:\"\";position:absolute;left:0;width:42px;height:22px;background-color:#d3d3d3;border-radius:11px;transform:translate3d(2px,2px,0) scaleZ(1);transition:all .25s linear}.form-switch[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]:after{content:\"\";position:absolute;left:0;width:22px;height:22px;background-color:#fff;border-radius:11px;box-shadow:0 2px 2px #0000003d;transform:translate3d(2px,2px,0);transition:all .2s ease-in-out}.form-switch[_ngcontent-%COMP%]:active   i[_ngcontent-%COMP%]:after{width:28px;transform:translate3d(2px,2px,0)}.form-switch[_ngcontent-%COMP%]:active   input[_ngcontent-%COMP%]:checked + i[_ngcontent-%COMP%]:after{transform:translate3d(16px,2px,0)}.form-switch[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{display:none}.form-switch[_ngcontent-%COMP%]   input.disabled[_ngcontent-%COMP%] + i[_ngcontent-%COMP%]{opacity:.3}.form-switch[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:checked + i[_ngcontent-%COMP%]{background-color:#007bfd}.form-switch[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:checked + i[_ngcontent-%COMP%]:before{transform:translate3d(18px,2px,0) scale3d(0,0,0)}.form-switch[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:checked + i[_ngcontent-%COMP%]:after{transform:translate3d(22px,2px,0)}label.form-switch[_ngcontent-%COMP%]{margin-bottom:0}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxToggleComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-toggle',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NgxToggleComponent),
        multi: true
      }],
      template: "<label class=\"form-switch\">\n    <input type=\"checkbox\" [id]=\"id\" [checked]=\"checked\" [class.disabled]=\"disabled\" [disabled]=\"disabled\"\n        (click)=\"onClick($event)\">\n    <i></i>\n</label>",
      styles: [".form-switch{display:inline-block;cursor:pointer;padding:0;-webkit-tap-highlight-color:transparent}.form-switch i{position:relative;display:inline-block;width:46px;height:26px;background-color:#d3d3d3;border-radius:23px;vertical-align:text-bottom;transition:all .3s linear}.form-switch i:before{content:\"\";position:absolute;left:0;width:42px;height:22px;background-color:#d3d3d3;border-radius:11px;transform:translate3d(2px,2px,0) scaleZ(1);transition:all .25s linear}.form-switch i:after{content:\"\";position:absolute;left:0;width:22px;height:22px;background-color:#fff;border-radius:11px;box-shadow:0 2px 2px #0000003d;transform:translate3d(2px,2px,0);transition:all .2s ease-in-out}.form-switch:active i:after{width:28px;transform:translate3d(2px,2px,0)}.form-switch:active input:checked+i:after{transform:translate3d(16px,2px,0)}.form-switch input{display:none}.form-switch input.disabled+i{opacity:.3}.form-switch input:checked+i{background-color:#007bfd}.form-switch input:checked+i:before{transform:translate3d(18px,2px,0) scale3d(0,0,0)}.form-switch input:checked+i:after{transform:translate3d(22px,2px,0)}label.form-switch{margin-bottom:0}\n"]
    }]
  }], null, {
    id: [{
      type: Input
    }],
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    checkedChange: [{
      type: Output
    }]
  });
})();
class NgxToggleModule {}
NgxToggleModule.ɵfac = function NgxToggleModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxToggleModule)();
};
NgxToggleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxToggleModule
});
NgxToggleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxToggleComponent],
      exports: [NgxToggleComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-toggle
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxToggleComponent, NgxToggleModule };
