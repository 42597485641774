<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form (ngSubmit)="register()" [formGroup]="form" #formDirective="ngForm">
            <span class="error text-danger">{{error}}</span>
            <div class="form-group">
              <label for="firstName">First Name</label>
              <input type="text" class="form-control" id="firstName" placeholder="John" name="firstName"
              formControlName="firstName">
            </div>
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input type="text" class="form-control" id="lastName" placeholder="Doe" name="lastName"
              formControlName="lastName">
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" placeholder="example@email.com" name="email"
              formControlName="email">
            </div>
            <div class="form-group">
              <p class="error-message" *ngIf="passwordError!=''">{{passwordError}}</p>
              <label for="password">Password</label>
              <input [type]="(showPasswordType ? 'text':'password')" placeholder="At least 8 characters"  class="form-control" id="password" name="password"
              formControlName="password">
              <img [src]="'/assets/' + (showPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showPasswordType")' class="eye-svg" alt="eye icon" />
            </div>
            <div class="form-group">
              <p class="error-message" *ngIf="passwordConfirmError!=''">{{passwordConfirmError}}</p>
              <label for="password">Confirm Password</label>
              <input [type]="(showConfirmPasswordType ? 'text':'password')" placeholder="At least 8 characters"  class="form-control" id="passwordConfirm" name="passwordConfirm"
              formControlName="passwordConfirm">
              <img [src]="'/assets/' + (showConfirmPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showConfirmPasswordType")' class="eye-svg" alt="eye icon" />
            </div>
            <div class="form-group">
              <button type="submit" class="btn cc-btn col-12 mt-3">Create Account</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>